<template>
    <div>
        <div class="abstract-block-1" style="background:rgba(245,245,249,1);">
            <div class="abstract-block-1-title">
                事業内容
            </div>
            <div class="abstract-block-1-body">
                <div>
                    <div class="abstract-block-1-desc">
                        我が社はＩＴパートナーネットワークと一緒になって、総合技術力でお客様（一般企業、ＳＩｅｒ、ＩＴ関連企業など）のＩＴサービス各分野を支援しています。
                        日本国内のビジネスにとどまらずに、中国国内のシステム開発会社と提携して低コスト、高品質のオフショア開発と、中国の日本企業様に向けに、日本で培った技術力を持って中国パートナーと一緒になってネイティブな日本語力でＩＴサービスを提供しています。
                    </div>
                    <div class="abstract-block-1-desc" style="margin-top:calc(100vw/1920*40)">
                        <img src="@/assets/images/company/icon_jiantou_you.png"
                            style="width:unset;height:100%;margin-right:10px;" alt="">
                        <div>コンピュータシステムの企画、設計、開発、運用、保守、販売、配信、管理、コンサルティング及びそれらの受託業務</div>
                    </div>
                    <div class="abstract-block-1-desc" style="margin-top:calc(100vw/1920*20)">
                        <img src="@/assets/images/company/icon_jiantou_you.png"
                            style="width:unset;height:100%;margin-right:10px;" alt="">
                        <div>
                            各種情報提供・収集・処理サービス業務
                        </div>
                    </div>
                    <div class="abstract-block-1-desc" style="margin-top:calc(100vw/1920*20)">
                        <img src="@/assets/images/company/icon_jiantou_you.png"
                            style="width:unset;height:100%;margin-right:10px;" alt="">
                        <div>
                            コンピュータシステム、コンピュータソフト開発の技術者及びコンピュータ操作要員の養成
                        </div>
                    </div>
                    <div class="abstract-block-1-title">
                        経営理念
                    </div>
                    <div class="abstract-block-1-desc" style="margin-top:calc(100vw/1920*20)">
                        技術者を大事にし、優秀な技術力で社会貢献を行います。
                    </div>
                </div>
                <div>
                    <img src="@/assets/images/company/img_gongsi1.png" alt="">
                </div>
            </div>
        </div>
        <div class="abstract-block-1">
            <div class="abstract-block-1-title">
                発展の歴史
            </div>
            <div class="abstract-block-3-body">
                <div class="container mt-5 mb-5">
                    <div class="row">
                        <div class="col-md-3">
                            <ul class="timeline-left">
                                <!-- <li>
                                    <p>いまに至る：</p>
                                </li> -->
                                <li>
                                    <p>2024年01月（令和6年）：</p>
                                </li>
                                <li>
                                    <p>2022年10月（令和4年）：</p>
                                </li>
                                <li>
                                    <p>2018年10月（平成30年）：</p>
                                </li>
                                <li>
                                    <p>2017年03月（平成29年）：</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-9">
                            <ul class="timeline">
                                <!-- <li>
                                    <p>盛んに発展している</p>
                                </li> -->
                                <li>
                                    <p class="p-nophone">事務所移転-〒101-0025 東京都千代田区神田佐久間町2-18-1 秋葉原STNフロント12F</p>
                                    <p class="p-phone">2024年01月（令和6年）</p>
                                    <p class="p-phone">事務所移転-〒101-0025 東京都千代田区神田佐久間町2-18-1 秋葉原STNフロント12F</p>
                                </li>
                                <li>
                                    <p class="p-nophone">事務所移転-〒105-0004 東京都港区新橋1丁目5番1号 ｽﾃﾌｧﾆｰ銀座中央通ビル3階</p>
                                    <p class="p-phone">2022年10月（令和4年）</p>
                                    <p class="p-phone">事務所移転-〒105-0004 東京都港区新橋1丁目5番1号 ｽﾃﾌｧﾆｰ銀座中央通ビル3階</p>
                                </li>
                                <li>
                                    <p class="p-nophone">事務所移転-〒110-0015 東京都台東区東上野１丁目２５－９ 桐谷ビル3F</p>
                                    <p class="p-phone">2018年10月（平成30年）</p>
                                    <p class="p-phone">事務所移転-〒110-0015 東京都台東区東上野１丁目２５－９ 桐谷ビル3F</p>
                                </li>
                                <li>
                                    <p class="p-nophone">設立，資本金980万円，代表者-司 宇</p>
                                    <p class="p-phone">2017年03月（平成29年）</p>
                                    <p class="p-phone">設立，資本金980万円，代表者-司 宇</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="abstract-block-1"
            style="background:rgba(245,245,249,1);padding:0 calc(100vw/1920*360);padding-bottom:calc(100vw/1920*50);">
            <div class="abstract-block-1-title">
                組織図
            </div>
            <div class="abstract-block-3-body">
                <img src="@/assets/images/company/img_jiagou.png" alt="">
            </div>
        </div>
        <div class="abstract-block-1"
            style="background:#1B2144;padding:0 calc(100vw/1920*360);padding-bottom:calc(100vw/1920*58)">
            <div class="abstract-block-4-title">
                会社概要
            </div>
            <div class="abstract-block-4-body">
                <div class="row abstract-block-4-tele">
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi1.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;商号：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 株式会社日新サービス</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi2.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;設立：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);">2017年03月30日(平成29年)</span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi3.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;代表者：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 司 宇</span>
                    </div>
                </div>
                <div class="row" style="margin-top:calc(100vw/1920*17)">
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi4.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;資本金：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 980万円</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi5.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;URL：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> www.nsservice.co.jp</span>
                    </div>
                    <div class="col-sm-6 col-xs-12" style="padding-right:0px ;">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi6.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36);
                            ">&nbsp;&nbsp;本店：</span>
                        <!-- 小分辨率显示：d-block d-md-none 大分辨率显示： d-none d-md-block-->
                        <!--电脑端显示内容 -->
                        <span class="d-none d-md-inline" style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);
                                    "> 〒101-0025 東京都千代田区神田佐久間町2-18-1 秋葉原STNフロント12F</span>
                        <!-- 手机端显示内容 -->
                        <span class="d-inline d-md-none" style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);
                                    ">〒101-0025 東京都千代田区<br><span
                                style="margin:40px; vertical-align: middle;">神田佐久間町2-18-1 <br><span
                                    style="margin:40px; vertical-align: middle;">秋葉原STNフロント12F</span></span></span>
                    </div>
                </div>
                <div class="row" style="margin-top:calc(100vw/1920*17)">
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi4.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;電話：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 03-5822-3021</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi5.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;FAX：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 03-5875-5764</span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi6.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;労働者派遣事業許可番号：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 派13-315697</span>
                    </div>
                </div>
                <div class="row" style="margin-top:calc(100vw/1920*17)">
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi4.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;Email：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> info@nsservice.co.jp</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi5.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;取引銀行：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    white-space:pre;
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    /* TODO  CSS*/
                                    line-height:calc(100vw/1920*36);"> みずほ銀行 上野支店<br><span
                                style="margin:67px; vertical-align: top;">北陸銀行
                                上野支店</span></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi6.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;プライバシーマーク登録番号：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 17004304(02)

                        </span>
                    </div>
                </div>
                <div class="row" style="margin-top:calc(100vw/1920*17)">
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi4.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"></span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi5.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    white-space:pre;
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <!-- <img src="@/assets/images/company/icon_liansifangshi6.png" alt=""> -->
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;アクセス：<br></span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);">
                            <img style="margin-left: 70px;" draggable="false"
                                src="@/assets/images/company/icon_ac-hibiya.png" alt="">
                            <span style="margin-left: 5px;">秋葉原駅まで徒歩1分</span><br>
                            <img style="margin-left: 70px;" draggable="false" src="@/assets/images/company/icon_ac-jr.png"
                                alt="">
                            <span style="margin-left: 5px;">秋葉原駅まで徒歩3分</span><br>
                            <img style="margin-left: 70px;" draggable="false"
                                src="@/assets/images/company/icon_ac-shinjuku.png" alt="">
                            <span style="margin-left: 5px;">岩本町駅まで徒歩4分</span><br>
                            <img style="margin-left: 70px;" draggable="false"
                                src="@/assets/images/company/icon_ac-ginza.png" alt="">
                            <span style="margin-left: 5px;">神田駅まで徒歩11分</span><br>
                            <!--新橋本店のアドレス  -->
                            <!-- <span style="margin:30px;">ＪＲ新橋駅「銀座口」より昭和通り方面に徒歩約5分</span><br>
                            <span style="margin:30px;">ＪＲ新橋駅「汐留口」より地下歩道をシオサイト方面へ徒歩約５分</span><br>
                            <span style="margin:30px;">東京メトロ銀座線新橋駅「２番出口」より徒歩約３分 </span><br>
                            <span style="margin:30px;">都営浅草線新橋駅改札より地下歩道をシオサイト方面へ徒歩約３分</span><br>
                            <span style="margin:30px;">都営大江戸線汐留口JRゆりかもめ新橋駅方面改札口より徒歩約2分</span><br>
                            <span style="margin:30px;">ゆりかもめ新橋駅よりシオサイト方面へ徒歩約３分</span><br> -->

                        </span>
                    </div>
                </div>
                <!-- <div class="row abstract-block-4-tele">
                    <div class="col-sm-3 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi1.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;URL：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> www.nsservice.co.jp</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi2.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;TEL：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 03-6879-9908</span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi3.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;本店：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 〒132-0035   東京都江戸川区平井３－２－２</span>
                    </div>
                </div>
                <div class="row" style="margin-top:calc(100vw/1920*17)">
                    <div class="col-sm-3 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi4.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;Email：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> info@nsservice.co.jp</span>
                    </div>
                    <div class="col-sm-3 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi5.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;FAX：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 03-5875-5764</span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <img src="@/assets/images/company/icon_liansifangshi6.png" alt="">
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,1);
                                    line-height:calc(100vw/1920*36)">&nbsp;&nbsp;本社事務所：</span>
                        <span style="font-size:calc(100vw/1920*16);
                                    font-family:Noto Serif JP;
                                    color:rgba(255,255,255,0.6);
                                    line-height:calc(100vw/1920*36);"> 〒110-0015  東京都台東区東上野１丁目25－9 桐谷ビル3F</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px) {
    .abstract-block-1 {
        padding-left: calc(100vw/1920*360);
        padding-bottom: calc(100vw/1920*30);
    }

    .abstract-block-1-title {
        font-size: calc(100vw/1920*24);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        line-height: calc(100vw/1920*33);
        text-align: left;
        padding-top: calc(100vw/1920*30);
    }

    .abstract-block-1-body {
        display: flex;
        margin-top: calc(100vw/1920*30);
    }

    .abstract-block-1 div {
        text-align: left;
    }

    .abstract-block-1 img {
        width: calc(100vw/1920*839);
        height: calc(100vw/1920*479);
    }

    .abstract-block-1-desc {
        display: flex;
        font-size: calc(100vw/1920*16);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: calc(100vw/1920*32);
        margin-right: 35px;
    }

    ul.timeline {
        list-style-type: none;
        position: relative;
    }

    ul.timeline:before {
        content: ' ';
        /* background: #0262FF; */
        border-right: 2px dashed #0262FF;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 0;
        height: 100%;
        z-index: 400;
    }

    ul.timeline>li {
        line-height: 50px;
        padding-left: 20px;
        font-size: calc(100vw/1920*18);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    ul.timeline>li:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #0262FF;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400;
        margin-top: 15px;
    }

    .timeline-left {
        padding: 0;
        list-style: none;
        font-size: calc(100vw/1920*14);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: calc(100vw/1920*20);
        line-height: 50px;
        text-align: right;
    }

    .abstract-block-3-body img {
        width: 100%;
        height: 100%;
        margin-top: 30px;
    }

    .abstract-block-4-title {
        font-size: calc(100vw/1920*24);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: #FFFFFF;
        line-height: calc(100vw/1920*33);
        text-align: left;
        padding-top: calc(100vw/1920*65);
        margin-bottom: calc(100vw/1920*40)
    }

    .abstract-block-4-body img {
        width: calc(100vw/1920*20);
        height: calc(100vw/1920*20);
    }

    .p-phone {
        display: none;
    }


}

@media screen and (max-width:768px) {
    .abstract-block-1 {
        padding-left: 20px;
        padding-bottom: calc(100vw/1920*30);
    }

    .abstract-block-1-title {
        font-size: 14px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        line-height: 16px;
        text-align: left;
        padding-top: 20px;
    }

    .abstract-block-1-body {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 12px;
    }

    .abstract-block-1 div {
        font-size: 12px !important;
        text-align: left;
    }

    .abstract-block-1 span {
        font-size: 12px !important;
        text-align: left;
    }

    .abstract-block-1 img {
        width: 90vw;
        /* height: 100px; */
    }

    .abstract-block-1-desc {
        display: flex;
        font-size: 12px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        margin-right: 35px;
    }

    ul.timeline {
        list-style-type: none;
        position: relative;
    }

    ul.timeline:before {
        content: ' ';
        /* background: #0262FF; */
        border-right: 2px dashed #0262FF;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 0;
        height: 100%;
        z-index: 400;
    }

    ul.timeline>li {
        line-height: 50px;
        padding-left: 20px;
        font-size: 12px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    ul.timeline>li:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #0262FF;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400;
        margin-top: 15px;
    }

    .timeline-left {
        display: none;
        padding: 0;
        list-style: none;
        font-size: calc(100vw/1920*14);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: calc(100vw/1920*20);
        line-height: 50px;
        text-align: right;
    }

    .abstract-block-3-body img {
        width: 100%;
        height: 100%;
        margin-top: 30px;
    }

    .abstract-block-4-title {
        font-size: calc(100vw/1920*24);
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: #FFFFFF;
        line-height: calc(100vw/1920*33);
        text-align: left;
        padding-top: calc(100vw/1920*65);
        margin-bottom: calc(100vw/1920*40)
    }

    .abstract-block-4-body img {
        width: calc(100vw/1920*20);
        height: calc(100vw/1920*20);
    }

    .abstract-block-4-tele {
        display: unset !important;
    }

    .abstract-block-4-tele div {
        padding: 0;
    }

    .p-nophone {
        display: none;
    }


}

@media screen and (min-width:1920px) {
    .abstract-block-1 {
        padding-left: 360px;
        padding-bottom: 30px;
    }

    .abstract-block-1-title {
        font-size: 24px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
        line-height: 33px;
        text-align: left;
        padding-top: 30px;
    }

    .abstract-block-1-body {
        display: flex;
        margin-top: 30px;
    }

    .abstract-block-1 div {
        text-align: left;
    }

    .abstract-block-1 img {
        width: 839px;
        height: 479px;
    }

    .abstract-block-1-desc {
        display: flex;
        font-size: 16px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 32px;
        margin-right: 35px;
    }

    ul.timeline {
        list-style-type: none;
        position: relative;
    }

    ul.timeline:before {
        content: ' ';
        /* background: #0262FF; */
        border-right: 2px dashed #0262FF;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 0;
        height: 100%;
        z-index: 400;
    }

    ul.timeline>li {
        line-height: 50px;
        padding-left: 20px;
        font-size: 18px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    ul.timeline>li:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #0262FF;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400;
        margin-top: 15px;
    }

    .timeline-left {
        padding: 0;
        list-style: none;
        font-size: 14px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
        line-height: 50px;
        text-align: right;
    }

    .abstract-block-3-body img {
        width: 100%;
        height: 100%;
        margin-top: 30px;
    }

    .abstract-block-4-title {
        font-size: 24px;
        font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 33px;
        text-align: left;
        padding-top: 65px;
        margin-bottom: 40px
    }

    .abstract-block-4-body img {
        width: 20px;
        height: 20px;
    }

    .p-phone {
        display: none;
    }
}
</style>